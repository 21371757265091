

































































































import Vue from 'vue';
import { types } from '@truck-parking/tp-api';
import FreeParkingSpaces from '../components/FreeParkingSpaces.vue';

export default Vue.extend({
  name: 'Welcome',
  components: {
    FreeParkingSpaces,
  },
  data() {
    return {
      loadingUser: true,
      loadingVehicles: true,
      user: '',
      recentVehicles: [] as Array<types.RecentVehicle>,
    };
  },
  mounted() {
    this.$api.getUser().then((u) => {
      this.user = u.body.firstName;
      this.loadingUser = false;
    });

    this.$api.getRecentVehicles().then(({ body: rv }) => {
      this.recentVehicles = rv.recentVehicles;
      this.loadingVehicles = false;
    });
  },
});
